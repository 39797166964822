<template>
  <header class="header">
    <div>Arabamcom Frontend Challenge</div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px;
  font-size: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
</style>
